<template>
  <div class="preview-box user-console-admin-actions">
    <div class="preview-box-container">
      <div class="preview-box-action-container">
          <div class="preview-box-tools">
            <UserConsoleActionConfirmView
              :actionButtonText="action"
              :title="action"
              :action="actionAdminControl"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <div>
                  <label>Product</label>
                  <select v-model="actionAdminControl.product" placeholder="Choose a package">
                    <option value="" selected disabled>Choose a package</option>
                    <template v-for="type in packageList">
                      <option v-if="type.key"
                        :key="type" :value="type">{{type.name}}</option>
                    </template>
                  </select>
                </div>
                <div>
                  <label>Validity</label>
                  <input type="number" v-model="actionAdminControl.validity"
                  />
                </div>
                <div class="hide">
                  <label>Plan start date</label>
                  <input
                    type="date"
                    v-model="actionAdminControl.plan_start_date"
                  />
                </div>
                <div class="hide">
                  <label>Private Notes</label>
                  <textarea
                    rows="6"
                    v-model="actionAdminControl.private_notes"
                  />
                </div>
                <div class="hide">
                  <label>Customer Notes</label>
                  <textarea
                    rows="6"
                    v-model="actionAdminControl.customer_notes"
                  />
                </div>
              </template>
            </UserConsoleActionConfirmView>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  watch,
  onMounted,
} from 'vue';
import { ProjectList, AdminAction, PackageTypes } from '@/store/modules/admin';
import moment from 'moment';

import UserConsoleActionConfirmView from '@/components/admin/UserConsoleActionConfirmView.vue';

export const datePickerFormat = (date) => moment.unix(date).format('DD/MM/YYYY');

export default {
  props: {
    action: String,
    activeProject: Object,
  },

  components: {
    UserConsoleActionConfirmView,
  },

  setup(props) {
    const packageList = ref([]);
    let actionAdminControl = ref({});

    if (props.action === 'attach') {
      actionAdminControl = ref(
        new AdminAction(() => ProjectList.attachTrial({
          project: props.activeProject.key,
          product: actionAdminControl.value.product.key,
          validity: parseInt(actionAdminControl.value.validity, 10),
          plan_start_date: moment(`${actionAdminControl.value.plan_start_date}Z`).unix(),
          private_notes: actionAdminControl.value.private_notes,
          customer_notes: actionAdminControl.value.customer_notes,
        })),
      );
    } else {
      actionAdminControl = ref(
        new AdminAction(() => ProjectList.extendPlan({
          project: props.activeProject.key,
          product: actionAdminControl.value.product.key,
          validity: parseInt(actionAdminControl.value.validity, 10),
          plan_start_date: moment(`${actionAdminControl.value.plan_start_date}Z`).unix(),
          private_notes: actionAdminControl.value.private_notes,
          customer_notes: actionAdminControl.value.customer_notes,
        })),
      );
    }

    const fetchPackages = () => {
      if (props.action === 'extend') {
        const params = {
          key: props.activeProject.key,
        };
        ProjectList.getPackagesList(params).then((resp) => {
          packageList.value = resp.data.data.project_packages;
        });
      } else {
        const params = {
          active: true,
        };
        ProjectList.fetchPackageInfo(params).then((resp) => {
          const filteredPackage = resp.response.filter((packages) => packages.type !== 'free_plan');
          packageList.value = filteredPackage;
        });
      }
    };

    watch(() => actionAdminControl.value.product, () => {
      if (actionAdminControl.value.product.next_renewal_date && props.action === 'extend') {
        actionAdminControl.value.plan_start_date = moment.unix(
          actionAdminControl.value.product.next_renewal_date,
        ).format('YYYY-MM-DD');
      }
    });

    onMounted(() => {
      actionAdminControl.value.plan_start_date = moment().format('YYYY-MM-DD');
      actionAdminControl.value.product = '';
      actionAdminControl.value.validity = '';
      actionAdminControl.value.action = props.action;
      fetchPackages();
    });

    const resetForm = {
      project: '',
      product: '',
      validity: '',
      plan_start_date: '',
      private_notes: '',
      customer_notes: '',
    };

    const onActionDone = () => {
      Object.assign(actionAdminControl.value, resetForm);
    };

    return {
      actionAdminControl,
      onActionDone,
      PackageTypes,
      packageList,
    };
  },
};
</script>
<style lang="scss">
.user-console-admin-actions {
  &.preview-box {
    .preview-box-tools {
      margin: 0 auto;
      padding: 0!important;
      .btn {
        &.btn-sm {
          margin-bottom: 0;
          margin-right: 0;
        }
      }
      .hide {
        display: none;
      }
      .error-msg {
        padding: size(10) 0;
        color: var(--global--error--BackgroundColor);
      }
      .body {
        h2 {
          border-bottom: size(1) solid var(--global--main--Color);
          padding-bottom: size(10);
          margin: size(20) 0;
        }
        >div {
          label {
            margin-bottom: size(6);
            color: var(--global--main--Color);
            font-size: size(12);
            line-height: size(18);
          }
          select, input, textarea, p {
            display: block;
            width: 100%;
            border-radius: size(4);
            color: var(--global--main--Color);
            padding: size(6);
            font-size: size(14);
            line-height: size(20);
            background: none;
            outline: var(--global--main--Color);
            border: size(1) solid var(--global--main--Color);
            margin-bottom: size(10);
          }
          select {
            border: 0;
            outline: size(1) solid var(--global--main--Color);
            border-right: size(10) solid transparent;
            appearance: none;
            background-image: url('../../assets/dropdown-black.svg');
            background-repeat: no-repeat;
            background-position: top 12px right 0;
            background-size: size(10);

            option {
              color: var(--global--main--Color);
            }
          }
        }
      }
      p {
        &.text {
          display: inline-block;
          padding: 0;
          color: var(--global--white--Color);
          font-size: size(14);
          font-style: normal;
          margin-bottom: size(25);
        }
      }
      label {
        display: inline-block;
        vertical-align: top;
        margin-right: size(25);
        margin-top: 0;
        min-width: size(180);
        span {
          color: var(--global--error--BackgroundColor);
          padding-left: size(2);
        }
      }
      .tools {
        margin-top: size(25);
        text-align: right;
      }
      .error {
        padding: size(20) 0;
        color: var(--global--error--BackgroundColor);
        font-size: size(15);
      }
      .start-date, .end-date {
        display: inline-block;
        svg {
          width: size(12);
        }
      }
    }
    .generate-link-btn {
      text-decoration: underline;
      font-size: size(14);
      line-height: size(18);
      cursor: pointer;
      color: var(--admin--color-level2);
    }
  }
  .button-wrapper {
    text-align: right;
    margin-top: size(30);
  }
}
</style>
