<template >
  <div class="packages">
    <div v-if="isLoading" class="loader packages-loader">
      <div class="skeleton-box"></div>
    </div>
    <h3 v-if="packagesList.length > 0" class="title">Packages</h3>
    <div>
      <div v-for="packages in packagesList" :key="packages.key">
        <div class="packages-list">
          <div class="package-info">
            <div>
              <div class="package-details">
                <span v-if="$filters.isBeforeDate(packages?.expires[0]?.expires_at)">
                  <img  src="@/assets/historical-plan-icon.svg" alt="free-trial-plan"/>
                </span>
                <span v-else-if="packages.type==='free_plan' || packages.has_active_trail">
                  <img  src="@/assets/free-trial-icon.svg" alt="free-trial-plan"/>
                </span>
                <span v-else>
                  <img src="@assets/success-icon.svg" alt="sucees-plan" />
                </span>
                <div>
                  <h5 class="package-name free-plan" v-if="packages.type==='free_plan'
                  || packages.has_active_trail">Free Plan</h5>
                  <h5 v-else class="package-name">{{ packages.name }}</h5>
              </div>
              </div>
              <div class="additional-info">
                <h5 v-if="packages.type==='free_plan' || packages.has_active_trail"
                 class="package-name">{{ packages.name }}</h5>
                <div v-if="showRenew(packages?.expires)">
                  <p  v-if="$filters.isBeforeDate(packages?.expires[0]?.expires_at)" class="expiry-date">
                    <span class="expired">Expired on:</span>
                    {{ $filters.relativeShortDate(packages?.expires[0]?.expires_at) }}
                  </p>
                  <p v-else class="expiry-date">
                    <span className="active">Valid between: </span>
                    {{ $filters.relativeShortDate(packages?.expires[0]?.active_from, 'MMM DD, YYYY, hh:mm a') }} -
                    {{ $filters.relativeShortDate(packages?.expires[0]?.expires_at, 'MMM DD, YYYY, hh:mm a') }}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <a class="btn-style-common buy" v-if="packages.type==='free_plan' ||
               packages.has_active_trail || showBuy(packages.expires)"
              :href="link+'user/project/'+activeProject.key+
                '/wizard?step=payment&prepay='+packages.key+'|50'+'&package='
                +packages.key+'&quantity=1'">
                Buy
              </a>
              <div v-else-if="showRenew(packages?.expires)">
                <a v-if="packages.renewal_project_product_key" class="btn-style-common buy"
                :href="link+'user/project/'+activeProject.key+
                '/wizard?step=payment&prepay='+packages.key+'|50'+'&package='
                +packages.key+'&quantity='+packages.quantity+'&renewal_key='
                +packages.renewal_project_product_key"
                >Renew</a>
                <a v-else class="btn-style-common buy"
                :href="link+'user/project/'+activeProject.key+
                '/wizard?step=payment&prepay='+packages.key+'|50'+'&package='
                +packages.key+'&quantity='+packages.quantity"
                >Renew</a>
            </div>
            </div>
          </div>
          <div className="package-keys">
            <p class="label" v-if="packages.type==='free_plan'">Tournament keys</p>
            <p class="label" v-else-if="packages.type!=='standard_package'">
              {{ `${packages.type.split('_')[0]} key` || Keys }}</p>
            <KeyBox v-if="packages.type!=='standard_package'"
              :keys="packages.resource_keys"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="usage-info" :class="{'empty-list' : packagesList.length < 1}">
      <h2 class="usage-info__header">
        <svg>
          <use v-bind="{ 'xlink:href': '#usage-icon'}" />
        </svg>
        API Requests
      </h2>
      <router-link
        :to="{ name: 'usageReport', params: { projectKey: activeProject.key }}" class="usage-info__desc--link">
        Explore
      </router-link>
    </div>
    <div class="dashboard__graph-section--daywise-graph"
      v-if="linechartData && activeProject.usage && activeProject.hasValidLicense">
      <div class="daily-api-usage__chart-wrapper">
        <div class="daily-usage__amount-wrapper">
          <h4>Total API Requests in {{currentMonth}} </h4>
          <span>{{activeProject.usage.total_api_hits.toLocaleString('en-US')}}</span>
        </div>
        <div class="chart-div" v-if="activeProject && activeProject.usage
          && activeProject.usage.total_api_hits > 1">
          <line-chart :chartType="'line'"
          :dataArray=" activeProject && activeProject.usage
            && Object.values(activeProject.usage.day_wise_api_hits)"
          :dataSets="chartDataSets"
          :labels="apihitslabel" :borderColor="linechart.borderColor"
          :borderWidth="linechart.borderWidth" :elementRef="'yourChart'"/>
        </div>
        <div class="chart-div" v-else-if="activeProject && activeProject.usage
          && activeProject.usage.total_api_hits <= 0">
          <line-chart :chartType="'line'"
          :options="emptyState.options"
          :dataArray=" activeProject && activeProject.usage
            && Object.values(activeProject.usage.day_wise_api_hits)"
          :dataSets="chartDataSets"
          :labels="apihitslabel" :borderColor="linechart.borderColor"
          :borderWidth="linechart.borderWidth" :elementRef="'yourChart'"/>
          <div class="empty-state-graph api-hits">
            <hr>
          </div>
        </div>
      </div>
    </div>
    <div class="loader chart-loader" v-else>
      <div class="skeleton-box"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .packages {
    .title {
      @include font-styles($type: 'text', $size: 'lg', $weight: 'bold');
      padding-bottom: size(14);
      color: var(--global--main--title--Color);
    }
   .package-name {
      @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
      color: var(--global--main--title--Color);
      padding-bottom: size(2);
      &.free-plan {
        color: #5141BD;
        padding-bottom: size(4);

      }
    }

    .packages-list {
      background: var(--global--white--Color);
      box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
      0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
      border-radius: size(8);
      margin-bottom: size(14);
      padding: size(15);

      .label {
        color: #777777;
        margin-top: size(24);
        @include font-styles($type: 'label', $size: 'md');
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: size(4);
      }

      .key-box {
        border-bottom: 0;

        &__label {
          padding-bottom: 0;
        }
      }
      .package-keys {
        margin-left: size(32);
      }
    }
    .package-info {
      display: flex;
      justify-content: space-between;

      span {
        margin-right: size(12);
        svg {
          height: size(25);
          width: size(20);
        }
      }

      a {
        cursor: pointer;
      }
      .package-details {
        display: flex;
        align-items: center;
      }

      .additional-info {
        margin-left: size(32);
      }
      .expiry-date {
        @include font-styles($type: 'label', $size: 'sm');
        font-weight: 500;
        color: var(--global--gray--Color);

        .expired {
          color: #C83739;
          margin-right: size(0);
        }

        .active {
          color: #00A656;
          margin-right: size(0);
        }
      }
      .buy {
        min-width: size(76);
        text-align: center;
      }
    }

    .usage-info {
      margin: size(32) 0 size(18) 0;
      &.empty-list {
        margin-top: 0;
      }
      &__header {
        display: inline-block;
        @include font-styles($type: 'text', $size: 'lg', $weight: 'semibold');
        color: var(--global--main--title--Color);
        svg {
          fill: var(--global--usage-img--Color);
          width: size(19);
          height: size(19);
          display: inline-block;
          vertical-align: middle;
          margin-right: size(15);
        }
      }

      a {
        @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
        color: var(--global--common-link--Color);
        float: right;
      }
    }

  .chart-div {
    position: relative;
    margin-bottom: size(30);

    canvas {
      max-height: size(430);
    }
  }

  .daily-api-usage__chart-wrapper {
    .widget-box {
      width: 100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: 0;
      .chart-area-wrapper {
        width: 100%;
        overflow-x: auto;
        canvas {
          height: size(300);
        }
      }
    }
  }

  .daily-api-usage__chart-wrapper {
    background: var(--global--white--Color);
    box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
     0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    border-radius: size(8);
    padding-top: size(40);
    .daily-usage__amount-wrapper, .daily-usage__usage-wrapper {
      display: inline-block;
      margin-bottom: size(40);
      padding-left: size(40);

      h4 {
        @include font-styles(
          $type: 'label',
          $size: 'lg',
          $weight: 'regular'
        );
        padding-bottom: size(10);
      }

      span {
        @include font-styles(
          $type: 'text',
          $size: 'lg',
          $weight: 'bold'
        );
      }
    }
  }

  .empty-state-graph {
    padding: size(140) 0;
    background: var(--global--white--Color);
    top: size(-30);
    right: 0;
    position: absolute;
    left: 0;
    hr {
      height: size(3);
      background: #0085FF;
    }
    &.api-hits {
      hr {
        background: #0085FF;
      }
    }
  }
  .loader {
    --loader--box--BackgroundColor: #0055bf33;

    background: var(--loader--box--BackgroundColor);
    width: 100%;
    animation: fadein .5s;
    border-radius: size(10);
    height: size(440);

    &.packages-loader {
      height: size(200);
      margin-bottom: size(20);
    }
    &.chart-loader {
      margin-bottom: size(30);
    }

    .skeleton-box {
      display: inline-block;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-color: var(--loader--box--BackgroundColor);
      border-radius: size(10);

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(#f8f6ff, 0) 0,
          rgba(#f8f6ff, 0.2) 20%,
          rgba(#f8f6ff, 0.5) 60%,
          rgba(#f8f6ff, 0)
        );
        animation: shimmer 1s infinite;
        content: '';
      }
    }
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
}
</style>

<style lang="scss">
  .key-box-info-wrapper {
    margin-bottom: size(4);
  }
</style>

<script>
import { ProjectList } from '@/store/modules/admin';
import KeyBox from '@/components/KeyBox.vue';
import lineChart from '@/components/Charts/lineChart.vue';
import moment from 'moment';

export default {
  name: 'Packages-list',
  components: {
    KeyBox,
    lineChart,
  },
  props: {
    activeProject: Object,
    linechartData: Boolean,
    apihitslabel: Array,
    emptyState: Object,
    linechart: Object,
    link: String,
  },
  data() {
    return {
      packagesList: [],
      isLoading: false,
      currentMonth: null,
    };
  },
  methods: {
    getPackages() {
      this.isLoading = true;
      const params = {
        key: this.$props.activeProject.key,
      };
      ProjectList.getPackagesList(params).then((resp) => {
        this.packagesList = resp?.data?.data?.project_packages;
        this.currentMonth = moment().format('MMMM');
        this.isLoading = false;
      });
    },
    getPackageStartDate(expiresArray) {
      const currentDate = moment().format('YYYY-MM-DD');
      const beforeDate = [];
      const filteredDate = [];
      const dateArray = Object.values(expiresArray);
      dateArray.forEach((expires) => {
        const modifiedDate = moment.unix(expires.active_from).format('YYYY-MM-DD');
        if (!moment(modifiedDate).isBefore(currentDate)) {
          filteredDate.push(expires.active_from);
        } else {
          beforeDate.push(expires.active_from);
        }
      });

      return filteredDate.length > 0 ? filteredDate[filteredDate.length - 1] : beforeDate[0];
    },

    showRenew(expiresArray) {
      let isExpire = true;
      const dateArray = Object.values(expiresArray);
      dateArray.forEach((expires) => {
        if (!expires.expires_at) {
          isExpire = false;
        }
      });

      return isExpire;
    },

    showBuy(expiresArray) {
      const packageType = new Set();
      const dateArray = Object.values(expiresArray);
      dateArray.forEach((expires) => {
        packageType.add(expires.kind);
      });

      if (packageType.size === 1 && packageType.has('trail')) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    this.getPackages();
  },
};
</script>
