<template>
  <div class="priority-support" :class="layoutType">
    <template v-if="activeProject.project_license.license_key.isEnterprise">
      <!-- <div class="enterprise-wrapper">
        <img width="120"  style="display: inline-block;" src="@/assets/img/divya-img.png" />
        <div class="content-wrapper">
          <h3>Your Dedicated account manager</h3>
          <p>Ms. Divya Gurunath</p>
          <a :href="supportNumbers.managerMail.link" title="Support Mail"
          class="mail">{{supportNumbers.managerMail.label}}</a>
          <a :href="supportNumbers.usTollfree.link" title="US Tollfree Number">{{supportNumbers.usTollfree.label}}</a>
        </div>
      </div> -->
    </template>
    <div v-else class="standard-wrapper">
      <div>
        <h4>Get Priority Support</h4>
        <p>Subscribe Enterprise plan to get priority support, a dedicated manager and many more features</p>
        <a :href="sportsLink+'pricing/'" class="btn-style-common medium" title="Pricing">Learn more</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.priority-support {
  .enterprise-wrapper {
    background-image: url('../assets/priority-support-bg1.png'),
     url('../assets/priority-support-bg2.png');
    background-repeat: no-repeat, no-repeat;
    padding: size(30) size(50);
    border-radius: size(8);
    background-position: bottom right, top left;
    background-color: var(--global--priority-badge-BackgroundColor);
    background-size: size(120) size(120), size(120) size(140);
    width: size(610);
    height: size(184);
    max-width: size(610);
    img {
      display: inline-block;
      margin-right: size(36);
      vertical-align: top;
    }
    .content-wrapper {
      display: inline-block;
      max-width: size(220);
      h3 {
        font-size: size(13);
        line-height: size(20);
        padding-bottom: size(14);
        text-transform: uppercase;
        color: var(--global--white--Color);
      }
      p {
        @include font-styles($type: 'text', $size: 'md', $weight: 'bold');
        padding-bottom: size(6);
        color: var(--global--white--Color);
      }
      a {
        text-decoration: none;
        display: block;
        @include font-styles($type: 'label', $size: 'lg', $weight: 'normal');
        color: #F5FF78;
        &.mail {
          margin-bottom: size(6);
        }
      }
    }
  }
  .standard-wrapper {
    background-image: url('../assets/standard-bg.png');
    background-repeat: no-repeat;
    padding: size(20);
    border-radius: size(8);
    background-size: size(280) 100%;
    background-color: var(--global--white--Color);
    background-position: top 0 right 0;
    width: size(610);
    max-width: size(610);
    box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
     0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    height: size(200);
    div {
      max-width: size(295);
      margin-top: size(5);
    }
    h4 {
      @include font-styles($type: 'text', $size: 'md', $weight: 'bold');
      padding-bottom: size(6);
    }
    p {
      @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
      padding-bottom: size(20);
      max-width: size(384);
    }
  }
  &.dual-column {
    .standard-wrapper {
      width: 100%;
      max-width: 100%;
      background-position: top 0 right 0;
      div {
        max-width: size(450);
        p {
          max-width: size(310);
        }
      }
    }
    .enterprise-wrapper {
      width: 100%;
      max-width: 100%;
      .content-wrapper {
        max-width: 100%;
      }
    }
  }
}
</style>

<script>
import { mapState } from 'vuex';
import Config from '@/config';
import { supportNumbers } from '@/store/modules/project';

export default {
  name: 'PrioritySupport',
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
      activeProjectState: (state) => state.project.activeProjectState,
    }),
  },
  props: {
    layoutType: String, // dual-column || empty
  },
  data() {
    return {
      supportNumbers,
      sportsLink: Config.sportsHost,
    };
  },
};
</script>
