<template>
  <div class="credentials">
    <h3 class="credentials__header--text">Credentials</h3>
    <KeyBox class="credentials__key-box" :keyData="activeProject.key"
    :labelData="'Project Key'" />
    <KeyBox
      class="credentials__key-box"
      :keyData="activeProject.active_api_keys[0].api_key"
      :labelData="'API Key'"
      v-if="activeProject.active_api_keys && activeProject.active_api_keys.length"
    />
    <KeyBox
      class="credentials__key-box graphql"
      :keyData="playgroundLink+activeProject.key+'/graphql/'"
      :labelData="'GraphQL URL'"
      :isGraphql="true"
    />
  </div>
</template>

<style lang="scss" scoped>
.credentials {
  max-width: size(400);

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: size(40);
    &--text {
      @include font-styles($type: 'text', $size: 'lg', $weight: 'bold');
      padding-bottom: size(14);
      color: var(--global--main--title--Color);
    }
  }
  &__key-box {
    background: var(--global--white--Color);
    box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1),
     0 size(2) size(1) rgba(0, 0, 0, 0.06), 0 size(1) size(1) rgba(0, 0, 0, 0.08);
    border-radius: size(8);
    margin-bottom: size(14);
    padding: size(15);

    &.graphql {
      display: none;
    }
  }
}
</style>
<script>
import { mapState } from 'vuex';

import KeyBox from '@/components/KeyBox.vue';
import Config from '@/config';

export default {
  name: 'Credentials',
  components: { KeyBox },
  props: {
    association: Array,
    tournament: Array,
  },
  data() {
    return {
      playgroundLink: Config.playgroundHost,
    };
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
    }),
  },
};
</script>
